import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Slider from 'react-slick';
import gnss from "../../img/Geo-ICT Page/System Design and Architecture.jpg";
import seabed from "../../img/Geo-ICT Page/database-migration-1.png";
import tls from "../../img/Geo-ICT Page/Screenshot 2024-08-09 093236.png";
import Workflow from "../../img/Geo-ICT Page/7-places-workflow.png";
import sliderImg6 from "../../img/GeoSpatial/Electronic Navigation Chart.png";
import sliderImg1 from "../../img/GeoSpatial/Global Navigation Satellite System (GNSS).png";
import sliderImg3 from "../../img/GeoSpatial/Remote Sensing and Photogrammetry.png";
import sliderImg5 from "../../img/GeoSpatial/Seabed Topography.png";
import sliderImg4 from "../../img/GeoSpatial/Terrestrial Laser Scanning and 360 Photogrammetry Survey.png";
import sliderImg2 from "../../img/GeoSpatial/Underground and Utility Detection Survey.png";
import logo1 from "../../img/Geo-ICT Page/Arcgis.png";
import logo2 from "../../img/Geo-ICT Page/AutoCad.png";
import logo3 from "../../img/Geo-ICT Page/MicroSurvey-CAD-Logo-2k2.png";
import logo4 from "../../img/Geo-ICT Page/SketchUp.png";
import logo5 from "../../img/Geo-ICT Page/bentley-3d-cad.png";
import logo6 from "../../img/Geo-ICT Page/revit.png";
import logo7 from "../../img/Geo-ICT Page/trimble.png";
import LogoImage from '../../img/360Geoinfo_Logo_PNG.png';
import './GeoICT.css'; // Import CSS file

const tabsConfig = [
  { name: 'about', label: 'About', color: '#627965' },
  { name: 'services', label: 'Services', color: '#D6B892' },
  { name: 'contact', label: 'Contact', color: '#3D3528' }
];

const GeoICTPage = () => {
    const [clickEvent, setClickEvent] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(tabsConfig[0].color);
    const [path, setPath] = useState(null);
    const largeImageRef = useRef(null);
    const smallImagesRef = useRef(null);
    const textContentRef = useRef(null);
    const titleRef = useRef(null);

    const slideContents = [
        "Utilizing cutting-edge terrestrial laser scanning and 360 photogrammetry, we create precise 3D models, facilitating thorough site analysis and high-fidelity digital documentation.",
        "Leveraging advanced terrestrial laser scanning and 360-degree photogrammetry, we deliver detailed 3D models for comprehensive asset inspections, ensuring accurate site analysis and reliable digital documentation.",
        "Equipped with a LiDAR scanner, our drones can effortlessly survey diverse terrains, generating accurate 3D maps perfect for applications in forestry, mining, construction, archaeology, and infrastructure.",
        "Utilizing software such as Agisoft Metashape and Pix4D, we produce high-resolution orthophotos, ensuring accurate and distortion-free aerial imagery for precise geospatial applications.",
        "Leveraging GNSS technology, we offer high-precision geospatial positioning, enabling accurate data acquisition for complex mapping and surveying projects.",
        "Our seabed topography services offer detailed marine mapping, essential for understanding underwater terrains and supporting coastal and offshore projects.",
    ];

    const slideTitles = [
        "Laser Scanning",
        "Asset Inspection",
        "LiDAR Survey",
        "Orthophoto Processing",
        "Global Navigation Satellite System (GNSS)",
        "Seabed Topography",
    ];

    const sliderImages = [sliderImg1, sliderImg2, sliderImg3, sliderImg4, sliderImg5, sliderImg6];
    const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7]; // Replace with actual logo imports/paths

    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
        const options = {
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                }
            });
        }, options);

        observer.observe(largeImageRef.current);
        observer.observe(smallImagesRef.current);
        observer.observe(textContentRef.current);
        observer.observe(titleRef.current);

        return () => observer.disconnect();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        afterChange: (current) => {
            setActiveSlide(current);
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

  const handleDrawerButtonClick = (event) => {
    setClickEvent(event);
    setDrawerOpen(true);

    // Create ripple effect
    const ripple = document.createElement('span');
    const rect = event.currentTarget.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.classList.add('ripple');

    event.currentTarget.appendChild(ripple);

    // Remove ripple effect after animation
    setTimeout(() => {
      ripple.remove();
    }, 600);
  };

  const handleExitButtonClick = () => {
    setDrawerOpen(false); // Close the drawer when exit button is clicked
  };

  const handleNavClick = (event, newPath, color) => {
    setClickEvent(event);
    setPath(newPath);
    setDrawerOpen(false); // Close the drawer after clicking a link
    setBackgroundColor(color); // Set background color based on the clicked link

    // Delay the navigation to ensure the circle transition completes
    setTimeout(() => {
      setClickEvent(null);
      setPath(null);
    }, 500); // Duration should match the CircleTransition animation duration
  };

    return (
        <div className="geoict-container">
            <div className="geoict-main-content">
                <div className="logo-navigation">
                    <Link to="/">
                        <img src={LogoImage} alt="YourLogo" className="logo-image" />
                    </Link>
                        <div className="navbar-text">
                            <Link to="/geospatial" className="navbar-link">Geospatial</Link> .  
                            <Link to="/gis" className="navbar-link"> GIS</Link> .
                            <Link to="/geoscience" className="navbar-link">Geoscience</Link> .  
                            <Link to="/geoict" className="navbar-link"> Geo-ICT</Link>
                        </div>
                    <motion.button
                    className="other-drawer-button-GSpatial-content"
                    onClick={handleDrawerButtonClick}
                    whileTap={{ scale: 0.9 }} // Scale down on click
                    transition={{ duration: 0.2 }} // Duration of the scaling animation
                    >
                    <MenuIcon />
                    </motion.button>
                    <AnimatePresence>
                    {drawerOpen && (
                        <motion.div
                        className="other-full-screen-menu-GSpatial-content"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        >
                        <button className="other-exit-button-GSpatial-content" onClick={handleExitButtonClick}>
                            <CloseIcon className="other-close-icon-GSpatial-content" /> {/* Use CloseIcon */}
                        </button>
                        <ul>
                            <li>
                            <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                            </li>
                            <li>
                            <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                            </li>
                            <li>
                            <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                            </li>
                        </ul>
                        </motion.div>
                    )}
                    </AnimatePresence>
                    <div className="other-navbar-right-GSpatial-content">
                        <Link to="/contactcontent" className="other-contact-link-GSpatial-content">
                        <div className="other-contact-box-GSpatial-content">
                            <p className="other-contact-text-top-right-GSpatial-content">Contact Us</p>
                            <div className="other-logo-container-GSpatial-content">
                            <div className="other-logo-background-GSpatial-content">
                                <a href="https://wa.me/12345678901" target="_blank" rel="noopener noreferrer">
                                <WhatsAppIcon className="other-whatsapp-icon-GSpatial-content" />
                                </a>
                            </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="geoict-phone-view">
                    <div className="geoict-image-row">
                        <div className="geoict-image-large" ref={largeImageRef}>
                            <img src={gnss} alt="Large GeoSpatial" />
                        </div>
                        <div className="geoict-small-images-container" ref={smallImagesRef}>
                            <div className="geoict-small-images">
                                <img src={tls} alt="Small GeoSpatial 1" className="geoict-image-small" />
                                <img src={seabed} alt="Small GeoSpatial 2" className="geoict-image-small" />
                                <img src={Workflow} alt="Small GeoSpatial 2" className="geoict-image-small" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="geoict-carousel-container">
                        <div className="geoict-logos-container">
                            {logos.map((logo, index) => (
                                <img key={index} src={logo} alt={`Logo ${index}`} className="geoict-logo" />
                            ))}
                        </div>
                        <div className="geoict-title" ref={titleRef}>
                            <h2>GeoICT</h2>
                        </div>
                        <Slider {...settings}>
                            {sliderImages.map((image, index) => (
                                <div key={index} className={`geoict-carousel-card ${activeSlide === index ? 'active' : ''}`}>
                                    <div className={`geoict-carousel-card-content ${activeSlide === index ? 'active' : ''}`}>
                                        <div className="geoict-carousel-card-image">
                                            <div className="geoict-carousel-card-text">
                                                <div style={{ textAlign: 'center', color: 'white', fontSize: 30, fontFamily: 'Avenir Next Cyr', fontWeight: '450', wordWrap: 'break-word' }}>
                                                    {slideTitles[index]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>

                    <div className="geoict-text-content" ref={textContentRef}>
                        <p>{slideContents[activeSlide]}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeoICTPage;
