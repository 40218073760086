import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './ServicesPage.css'; // Ensure this CSS file includes necessary styling
import serviceImage from '../img/ServicesProfile.png'; // Image specific to the Services page
import arrowImg from '../img/arrow.png'; // Import your arrow image
import PageTransition from '../PageTransitionAntiClockWise'; // Import your anti-clockwise transition component

const tabsConfig = [
  { name: 'about', label: 'ABOUT', color: '#627965' },
  { name: 'services', label: 'SERVICES', color: '#D6B892' },
  { name: 'contact', label: 'CONTACT', color: '#3D3528' }
];

const wordingVariants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
  transition: { duration: 0.5 }
};

const imageVariants = {
  initial: { scale: 0.9, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 1.1, opacity: 0 },
  transition: { duration: 0.6 }
};

const ServicePage = () => {
  const [activeTab, setActiveTab] = useState('services');
  const [backgroundColor, setBackgroundColor] = useState(tabsConfig.find(tab => tab.name === 'services').color);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('service-body');

    // Clean up by removing the class when the component is unmounted
    return () => {
      document.body.classList.remove('service-body');
    };
  }, []);

  useEffect(() => {
    // Update the CSS variable whenever backgroundColor changes
    document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [backgroundColor]);

  const handleTabClick = (tab) => {
    const tabConfig = tabsConfig.find(t => t.name === tab);
    if (tabConfig) {
      document.documentElement.style.setProperty('--background-color', tabConfig.color);
      document.documentElement.style.setProperty('--background-image', `url('/path/to/your/image.jpg')`);
    }
  
    setActiveTab(tab);
    navigate(`/${tab}`);
  };  

  return (
    <motion.div
      className="service-page"
      transition={{ duration: 0.5 }}
    >
      <div className="service-container">
        <div className="service-content">
          <motion.div
            className="service-text"
            variants={wordingVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={wordingVariants.transition}
          >
            <div className="service-header">
              <p className="service-subheading">02. SERVICES</p>
              <h1 className="service-main-title">
                <span className="service-text-white">Precision through </span>
                <span className="service-text-highlight">geoexcellence</span>
              </h1>
              {/* Delete the button */}
              <button className="service-button" onClick={() => navigate('/servicecontent')}>
                <span className="learn-more-text">Learn More</span>
                <img src={arrowImg} alt="Arrow" />
              </button>
            </div>
            <div className="mini-nav-tabs-service">
              {tabsConfig.map((tab) => (
                <a
                  key={tab.name}
                  onClick={() => handleTabClick(tab.name)}
                  className={`tab-link-service ${activeTab === tab.name ? 'active' : ''}`}
                  href="#"
                >
                  {tab.label}
                </a>
              ))}
            </div>
            <AnimatePresence>
              {tabsConfig.map((tab) => (
                activeTab === tab.name && (
                  <PageTransition key={tab.name}>
                    <motion.div
                      variants={wordingVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition={wordingVariants.transition}
                    >
                      {/* Add content specific to each tab if needed */}
                    </motion.div>
                  </PageTransition>
                )
              ))}
            </AnimatePresence>
          </motion.div>
          <motion.div
            className="service-image-container"
            initial="initial"
            animate="animate"
            variants={imageVariants} 
            exit="exit"
          >
            <img src={serviceImage} alt="Description of Service Image" className="service-image" />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default ServicePage;
